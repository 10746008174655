import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const prieredunsudiste = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Prière d'un sudiste" />
    <h3 className='underline-title'>Prière d'un sudiste</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Seigneur<br />
        <span className="par-starting"></span>faites que je devienne comme Centurion<br />
        <span className="par-starting"></span>- le soulard romain<br />
        <span className="par-starting"></span>- ce croyant étranger<br />
        <span className="par-starting"></span>sans pareil en Israel<br />
      </p>
      <p>
        Seigneur<br />
        <span className="par-starting"></span>je suis parmi ceux de la onzième heure<br />
        <span className="par-starting"></span>- je suis donc des tard-venus<br />
        <span className="par-starting"></span>faites que je sois parmi vos ouvriers<br />
        <span className="par-starting"></span>ouvriers envoyés à la vigne<br />
        Je toucherai quand-même un denier<br />
        <span className="par-starting"></span>comme l'embauché du point du jour<br />
      </p>
      <p>
        Seigneur<br />
        <span className="par-starting"></span>je suis parmi les conviés de la rue <br />
        <span className="par-starting"></span>- va-nu-pieds et clochards donc<br />
        <span className="par-starting"></span>faites que je sois parmi vos invités <br />
        <span className="par-starting"></span>les convives du festin nuptial <br />
        Je mangerai tout de même à la table royale <br />
        <span className="par-starting"></span>à la place des hôtes de la Noce <br />
      </p>
      <p>
        Je suis du Sud <br />
        Je veux être parmi ceux <br />
        <span className="par-starting"></span>qui descendront du Levant<br />
        <span className="par-starting"></span>et qui monteront du Couchant <br />
        Je veux être avec eux:<br />
        <span className="par-starting"></span>les venants d'Orient <br />
        <span className="par-starting"></span>les arrivants d'Occident <br />
      </p>
      <p>
        Mon vouloir est d'être à table <br />
        <span className="par-starting"></span>avec les enfants de la discipline <br />
        <span className="par-starting"></span>les enfants d'Abraham <br />
        <span className="par-starting"></span>les enfants de Dieu donc!<br />
      </p>
      <p>
        Seigneur<br />
        <span className="par-starting"></span>je veux être avec vos enfants <br />
        <span className="par-starting"></span>dans le royaume des Cieux <br />
        <span className="par-starting"></span>tout en demeurant authentique <br />
      </p>
      <p>
        Seigneur<br />
        <span className="par-starting"></span>vous ignorez les couleurs des peaux<br />
        <span className="par-starting"></span>et leurs origines <br />
        <span className="par-starting"></span>vous n'en avez fait aucune allusion<br />
        <span className="par-starting"></span>seul le COEUR comptant pour VOUS:<br />
        <span className="par-starting"></span>CREDO!<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default prieredunsudiste
